import './community-crop.scss'; 
function flick(sel) {
  $(sel).toggle()
  return true;
}

function grdn_menu() { return flick('#our-gardens-menu') }

function prep_menu() { return flick('#preparation-menu') }

$().ready(function () {

  grdn_menu()
  if ($('#content').hasClass('garden')) { grdn_menu() }
  $('#our-gardens').click(grdn_menu)
  $('#our-gardens + .caret').click(grdn_menu)

  prep_menu()
  if ($('#content').hasClass('preparation')) { prep_menu() }
  $('#preparation').click(prep_menu)
  $('#preparation + .caret').click(prep_menu)

  $('#content.faq dd').toggle();
  $('#content.faq dt').click(function (e) {
    $(e.target).next('dd').toggle();
    return true;
  })

  $('#content.faq h1').after($('<a id="show-all" href="#">').text("show all"))
  $('#content.faq #show-all').click(function (e) {
    $('#content.faq dd').toggle();
    var option = $('#content.faq dd').is(':visible') ? 'hide' : 'show';
    $('#content.faq #show-all').text(option + ' all');
    return true;
  })

});